// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
import "@hotwired/turbo-rails";

require("jquery");
require("easy-autocomplete");
require("packs/searches");
require("packs/shared");
require("packs/preview_image");
require("jquery-mask-plugin");

$.jMaskGlobals.watchDataMask = true;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import { Application } from "stimulus";
const application = Application.start();

import Flatpickr from "stimulus-flatpickr";
require("flatpickr/dist/flatpickr.css");
application.register("flatpickr", Flatpickr);

import toastr from "toastr";
window.toastr = toastr;

require("bootstrap");
require("stackedmenu");
require("perfect-scrollbar");
require("datatables.net-bs4");

require("chartkick");
require("chart.js");

window.moment = require("moment");
require("theme");

import "select2";
import "select2/dist/css/select2.css";
require("select2/dist/js/select2.full.js");

import "controllers";
import "plugins";
import '@client-side-validations/client-side-validations/src'
import "trix/dist/trix.css";

import $ from "jquery";
global.$ = jQuery;
require("@nathanvda/cocoon");
require("packs/upload_images_uppy");

require("plugins/aos_magic_scroll");
require("plugins/modals.js");
require("trix");
require("@rails/actiontext");
require("packs/turbostream_custom_actions")

window.GMaps = require("plugins/gmaps.js");

document.addEventListener("turbo:before-visit", function () {
  Turbo.clearCache();
})

$(document).on("turbo:before-cache", function () {
  $("select").select2("destroy");
});

$(window).on("load", function () {
  if ($('.page').hasClass('has-sidebar')) {
    $('.sidebar-backdrop').remove();
  }
});

$(document).on('turbo:load', function() {
  $('.select2').select2();
  $(document).on('cocoon:after-insert', function(e, insertedItem) {
    console.log('concoon:after-insert')
    insertedItem.find('.select2').select2();
    $('.save-participants').removeClass('d-none')
  });
});

$(document).on('turbo:frame-load', function(event) {
  let id = $(event.target).find('.sortable-lists').attr('id')
  if (id) {
    NestableList.initialize(id);
  }
});
