Turbo.StreamActions.load_nestable_list = function () {
  if (this.target) {
    NestableList.initialize(this.target);
  }
};

Turbo.StreamActions.open_checklist_modal = function () {
  if (this.target) {
    $(`#${this.target}`).trigger('click')
  }
};
